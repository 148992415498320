import { SkipNext, SkipPrevious } from "@mui/icons-material";
import { FormControlLabel, Checkbox, IconButton } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useMemo, useState } from "react";
import videos from "./data/song_list_build.json";

const allMusicFiles = videos
  .slice()
  .reverse()
  .filter((v) => v.has_music && v.audio_s3_url)
  .map((v) => {
    return {
      ...v,
      audio_s3_url: v.audio_s3_url as string,
    };
  });

export default function Music() {
  //   console.log("---Music---");
  const [nowPlayingIndex, setNowPlayingIndex] = useState<number>(0);
  const [userInit, setUserInit] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const [shuffle, setShuffle] = useState(false);
  const musicFiles = useMemo(() => {
    if (!shuffle) {
      return allMusicFiles;
    }

    let remainingFiles = [...allMusicFiles];
    const randomFiles = [];
    for (let i = 0; i < allMusicFiles.length; i++) {
      let randIndex = Math.floor(Math.random() * remainingFiles.length);
      randomFiles.push(remainingFiles[randIndex]);
      remainingFiles.splice(randIndex, 1);
    }
    return randomFiles;
  }, [shuffle]);

  return (
    <>
      <h1>Music Player</h1>

      <div className="border-solid p-2">
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoplay}
                onChange={({ currentTarget }) => {
                  setAutoplay(currentTarget.checked);
                }}
              />
            }
            label="Autoplay"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={shuffle}
                onChange={({ currentTarget }) => {
                  setShuffle(currentTarget.checked);
                }}
              />
            }
            label="Shuffle"
          />
        </div>

        {nowPlayingIndex !== undefined ? (
          <div className="mb-4 whitespace-nowrap overflow-hidden text-ellipsis">
            <span>
              Now Playing {nowPlayingIndex + 1}/{musicFiles.length}:{" "}
            </span>
            <a
              className="text-white"
              href={`#${musicFiles[nowPlayingIndex].id}`}
            >
              {musicFiles[nowPlayingIndex].display_name}
            </a>
          </div>
        ) : (
          <div className="mb-4 invisible">null</div>
        )}

        <div>
          <audio
            className="w-full"
            controls
            src={
              nowPlayingIndex !== undefined
                ? musicFiles[nowPlayingIndex].audio_s3_url
                : undefined
            }
            onEnded={() => {
              next();
            }}
            onPlay={() => {
              if (!userInit) {
                setUserInit(true);
              }
            }}
            autoPlay={userInit && autoplay}
          ></audio>
        </div>

        <div className="flex gap-2">
          <IconButton
            disabled={nowPlayingIndex === undefined}
            onClick={() => {
              const nextIndex =
                nowPlayingIndex === undefined || nowPlayingIndex === 0
                  ? musicFiles.length - 1
                  : nowPlayingIndex - 1;
              setNowPlayingIndex(nextIndex);
              if (!userInit) {
                setUserInit(true);
              }
            }}
          >
            <SkipPrevious />
          </IconButton>

          <IconButton
            disabled={nowPlayingIndex === undefined}
            onClick={() => {
              next();
              if (!userInit) {
                setUserInit(true);
              }
            }}
          >
            <SkipNext />
          </IconButton>
        </div>
      </div>

      <div className="my-4">Total Songs: {musicFiles.length}</div>

      <div className="overflow-x-hidden overflow-y-scroll whitespace-nowrap h-72">
        {musicFiles.map((v, i) => {
          return (
            <div
              id={v.id}
              className="flex gap-2 mb-1 items-baseline"
              key={v.id}
            >
              <div>
                <IconButton
                  disabled={i === nowPlayingIndex}
                  onClick={() => {
                    setNowPlayingIndex(i);
                    if (!userInit) {
                      setUserInit(true);
                    }
                  }}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              </div>

              <div
                className="flex gap-2"
                style={{
                  backgroundColor: nowPlayingIndex === i ? "gray" : "inherit",
                }}
              >
                <div>{i + 1}.</div>
                <div>{v.display_name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );

  function next() {
    const nextIndex =
      nowPlayingIndex === undefined || nowPlayingIndex + 1 === musicFiles.length
        ? 0
        : nowPlayingIndex + 1;
    setNowPlayingIndex(nextIndex);
  }
}
