import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { YoutubeFrame } from "./components/YoutubeFrame";
import videos from "./data/song_list_build.json";
import { ArrowUpward } from "@mui/icons-material";

type Video = typeof videos[0];
type VideoType = "music" | "non_music" | "all";

const sortedVideos = videos.slice().reverse();

export default function Home() {
  // console.log("---Home---");
  const [playingVideo, setPlayingVideo] = useState<Video>();
  const [filterValue, setFilterValue] = useState("");
  const [videoType, setVideoType] = useState<VideoType>("all");
  const [filteredVideos, setFilteredVideos] = useState(sortedVideos);

  useEffect(() => {
    const hashId = window.location.hash.match(/#(?<id>.*)/)?.groups?.id;
    if (hashId) {
      setTimeout(() => {
        document.getElementById(hashId)?.scrollIntoView();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    const newFilteredVideos = getFilteredVideos(filterValue, videoType);
    setFilteredVideos(newFilteredVideos);
    setPlayingVideo((currentVideo) =>
      newFilteredVideos.some((v) => v.id === currentVideo?.id)
        ? currentVideo
        : undefined
    );
  }, [filterValue, videoType]);

  return (
    <>
      <h1>Video List</h1>

      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          (ev.target as any)[0]?.blur();
        }}
      >
        <FormControl className="w-full">
          <InputLabel>Filter</InputLabel>
          <OutlinedInput
            type="text"
            value={filterValue}
            onChange={({ target }) => {
              setFilterValue(target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setFilterValue("");
                  }}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Filter"
          />
        </FormControl>
      </form>

      <div>
        <FormControl>
          {/* <FormLabel>
              Which Videos to show...
            </FormLabel> */}
          <RadioGroup
            value={videoType}
            row
            onChange={({ target: { value } }) => {
              const newVideoType = value as VideoType;
              setVideoType(newVideoType);
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="music" control={<Radio />} label="Music" />
            <FormControlLabel
              value="non_music"
              control={<Radio />}
              label="Non-Music"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="my-2">
        <div>
          Showing {filteredVideos.length}/{sortedVideos.length} videos
        </div>
        {playingVideo && (
          <div>
            <span>Now Playing: </span>
            <a className="text-white" href={`#${playingVideo.id}`}>
              {playingVideo.title}
            </a>
          </div>
        )}
      </div>

      {filteredVideos.map((v) => {
        return (
          <div key={v.id} className="mb-8">
            <div className="mb-4">
              <h3 id={v.id} className="mb-0">
                {v.number}. {v.display_name}
              </h3>
              <div>
                <small>{v.title}</small>
              </div>
            </div>
            <div>
              {playingVideo?.id === v.id ? (
                <YoutubeFrame videoId={v.id} />
              ) : (
                <>
                  <img
                    loading="lazy"
                    className="w-full"
                    src={v.thumbnail_urls[3]}
                    alt={v.title}
                  />
                </>
              )}
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              <Button
                variant="outlined"
                className="w-24 !text-xs h-9"
                onClick={() => {
                  setPlayingVideo((currentVideo) => {
                    return currentVideo?.id === v.id ? undefined : v;
                  });
                }}
              >
                {playingVideo?.id === v.id ? "Stop" : "Play"}
              </Button>
              <Button
                variant="outlined"
                className="w-24 !text-xs h-9"
                onClick={() => {
                  const url = `${window.location.origin}/#${v.id}`;
                  navigator.clipboard.writeText(url).catch(console.error);
                  console.log("Copied video link to clipboard", url);
                }}
              >
                Copy URL
              </Button>
              <Button
                className="w-24 !text-xs h-9"
                variant="outlined"
                target="_blank"
                href={v.url}
              >
                Youtube
              </Button>
              {v.video_s3_url && (
                <Button
                  className="w-24 !text-xs h-9"
                  variant="outlined"
                  target="_blank"
                  href={v.video_s3_url}
                >
                  Download
                </Button>
              )}
            </div>
          </div>
        );
      })}

      <div className="fixed bottom-2 right-36 hidden md:block">
        <IconButton
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <ArrowUpward sx={{ fontSize: 40 }} />
        </IconButton>
      </div>
    </>
  );

  function getFilteredVideos(newFilter: string, videoType: VideoType) {
    const newFilterLower = newFilter.toLowerCase();
    const newFilteredVideos = sortedVideos
      .filter((v) => {
        switch (videoType) {
          case "music":
            return v.has_music;
          case "non_music":
            return !v.has_music;
          case "all":
            return true;
          default:
            throw new Error("react is too stupid to understand this");
        }
      })
      .filter((v) => {
        const lowerDisplay = v.display_name.toLowerCase();
        const lowerTitle = v.title.toLowerCase();
        const propertyIncludes =
          lowerDisplay.includes(newFilterLower) ||
          lowerTitle.includes(newFilterLower) ||
          newFilterLower.includes(v.id.toLowerCase());
        if (propertyIncludes) {
          return true;
        }

        if (newFilterLower.includes("&") || /\band\b/.test(newFilterLower)) {
          const andFilter = newFilterLower.replaceAll("and", "&");
          const andCleansedValue = lowerDisplay
            .concat(lowerTitle)
            .replaceAll("and", "&");
          if (andCleansedValue.includes(andFilter)) {
            return true;
          }
        }

        return false;
      });

    return newFilteredVideos;
  }
}
