import {
  AppBar,
  Toolbar,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Link, NavLink, Outlet } from "react-router-dom";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static">
        <Toolbar style={{ gap: 25 }}>
          <Typography variant="h6" component="div">
            <Link className="no-underline text-white" style={{}} to="/">
              Mark Remick Videos
            </Link>
          </Typography>

          <div>
            <NavLink
              className="no-underline text-white"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "grey" : "inherit",
                  padding: 10,
                };
              }}
              to="/"
            >
              Home
            </NavLink>
          </div>

          <div className="whitespace-nowrap">
            <NavLink
              className="no-underline text-white"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "grey" : "inherit",
                  padding: 10,
                };
              }}
              to="/music"
            >
              Music Player
            </NavLink>
          </div>
        </Toolbar>
      </AppBar>

      <div className="w-10/12 md:w-1/2 m-auto" style={{ maxWidth: "560px" }}>
        <Outlet />
      </div>
    </ThemeProvider>
  );
}
